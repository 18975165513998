<template>
    
  <div class="board-container board-inquiry">
    
    <div class="page-title"></div>


    <validateForm ref="form">
      
      <div class="inquiry-subject">카테고리<span class="color-1">* </span></div>
      <div class="layout-filter-box">
        <mini-filter :opt="opt.cateCode" :target="cateCode" :targetTitle="'cateCode'" :title="'카테고리'" :inputType="'radio'" :isSetDefaultValue="true" @set="setMiniFilter"/>
      </div>
        
      <div class="inquiry-subject">제목<span class="color-1">* </span></div>
      <input-field v-model="title" :validate="rules.title" placeholder="제목을 입력해주세요." maxLength="50"/>

      <div class="inquiry-subject">본문내용<span class="color-1">* </span></div>
      <input-field multiline rows="10" v-model="content" :validate="rules.content" :placeholder="`내용을 입력하세요.\n(최소 10자 ~ 최대 2,000자 입력 가능, 사진 최대 10장까지 첨부가능)`"/>

      <upload-image-group :attach="attach" :newAttach="newAttach" @upload="addAttach" @removeNew="removeNewAttach" @remove="removeAttach"/>

    </validateForm>

    <div class="mt-50 ta-center">
      <router-link to="/ggomunity" class="mr-5 btn w-100 btn-outline color-6">취소</router-link>
      <button @click="save" class="btn w-100 color-1">{{ idx ? '수정' : '등록' }}</button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import MiniFilter from '@/components/common/filter/MiniFilter'
import UploadImageGroup from "@/components/ggomunity/UploadImageGroup"
export default {
  components: {MiniFilter, UploadImageGroup},
  data() {
    return {
      idx: this.$route.name === 'ggomunity/new' ? 0 : this.$route.params.idx,
      opt : {
        cateCode: [],
      },
      cateCode: null,
      title: null,
      content: null,
      attach: [],
      newAttach: [],
      rules: {
        title: [v => !!v || this.$msg('required.input')],
        content: [v => !!v || '본문 내용을 10~2,000자 이내로 작성해주세요.', v => !(v.length < 10) || '10자 이상의 텍스트를 입력해주세요.', v => !(v.length > 2001) || '최대 글자 수(2,000자)를 초과하였습니다.'],
      },
    }
  },
  created() {
    this.setOptCateCode()
    if (this.idx > 0) this.setItem()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setOptCateCode() {
      const { result, data } = await this.$api.getCtgry({ cate: 80 });
      if (result === 'success' && data.length > 0) {
        this.opt.cateCode = data.reduce((list, d) => {
          d.text = d.categoryName
          d.value = d.cateCode
          list.push(d)
          return list
        }, [])
        this.cateCode = this.opt.cateCode[0].value
      }
    },
    setFilters(obj) {
      this.cateCode = obj.cateCode
      this.petCode = obj.petCode
      this.keyword = obj.keyword
      this.disease = obj.disease
      this.search()
    },
    setMiniFilter(target, value) {
      this[target] = value
    },
    async setItem() {
      const { result, data, attach } = await this.$api.getBoardDetail({ cateCode: 80, boIdx: this.idx })
      switch (result) {
        case 'success':
          this.cateCode = data.cateCode
          this.cateName = data.categoryName
          this.title = data.title
          this.content = data.content
          this.attach = attach
          break;
        case 'blind':
          this.alertBackPopup(this.$msg('ok.blinded'))
          break;
        case 'deleted':
          this.alertBackPopup(this.$msg('ok.deleted'))
          break;
        default:
      }
    },
    addAttach(data) {
      this.newAttach.push(data)
    },
    removeNewAttach(index) {
      this.newAttach.splice(index, 1);
    },
    removeAttach(atchIdx) {
      this.attach = this.attach.filter(a => a.atchIdx !== atchIdx)
    },
    async save() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.saveGgomunity({
          id: this.idx,
          cateCode: this.cateCode,
          title: this.title,
          content: this.content,
          attachFile: this.newAttach,
        });
        if (result === 'success') {
          this.alertSavePopup(this.idx === 0 ? this.$msg('ok.insert') : this.$msg('ok.update'))
        }
        else {
          this.alertSavePopup(this.$msg('fail'))
        }
      }
    },
    alertSavePopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
          if (!this.idx) this.$router.push(`/ggomunity`)
          else this.close()
        }
      })
    },
    alertBackPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: '',
        okayCallback: () => {
          this.setMsgPopup()
          this.$router.go(-1)
        },
      })
    },
    close() {
      this.$router.go(-1)
    },
  }
}
</script>
