<template>
  <div class="attachments-box">
    <input type="file" multiple accept="image/gif, image/jpg, image/jpeg, image/png" ref="imageInput" @change="uploadImage($event)" style="display:none;">

    <div class="attachments" @click="$refs.imageInput.click()">사진 첨부하기</div>
    <div class="attachments-list">
      <div v-for="(item, index) in attach" :key="`file${index}`"class="item">
        <img :src="item.src" :alt="item.realFileName" :title="item.realFileName">
        <div class="delete" @click="confirmRemovePopup(item.atchIdx)"></div>
      </div>

      <div v-for="(item, index) in newAttach" :key="`newFile${index}`" class="item">
        <img :src="item.src" :alt="item.file_name" :title="item.file_name">
        <div class="delete" @click="removeNew(index)"></div>
      </div>

      <div v-if="msg" class="error-message">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import {mapActions} from 'vuex'
export default {
  name: 'uploadImageGroup',
  props: ['attach', 'newAttach'],
  data() {
    return {
      msg: null,
      srcList: null,
    }
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    uploadImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      this.msg = null
      let files = event.target.files || event.dataTransfer.files

      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.msg = this.$msg('image.ext')
            return false
          }

          let fileExt = this.$getExts(file.name, false)
          let isImg = this.$isImages(fileExt)
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true
            } else {
              this.msg = this.$msg('image.limit')
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      vm.$emit('upload', { file_data: reader.result, file_name: _file.name, src: URL.createObjectURL(_file) })
                      event.target.value = ''
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  vm.$emit('upload', { file_data: reader.result, file_name: file.name, src: URL.createObjectURL(file) })
                  event.target.value = ''
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
    removeNew(index) {
      this.$emit('removeNew', index)
    },
    async remove(atchIdx) {
      this.setMsgPopup()
      const { result } = await this.$api.removeAttachFile({ idx: atchIdx });
      this.alertPopup(result === 'success' ? this.$msg('ok.delete') : this.$msg('fail'))
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
    confirmRemovePopup(atchIdx) {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove.file'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.remove(atchIdx)
          this.$emit('remove', atchIdx)
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>